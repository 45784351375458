<template>
    <div class="auth-page">
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-12 col-md-6 d-none d-md-block">
            <AuthLeft :paragraph="para" :member="mem" :button2="btn" />
          </div>
          <div class="col-12 col-md-6">
            <div class="auth-page-right d-flex flex-column justify-content-center">
             
              <div class="auth-page-right-content">
                <div class="heading px-2 mb-0">
                  <h6>Instructions To Add Shopify Store</h6>
  
                  <p v-if="errorMessage != null" class="error">
                    {{ errorMessage }}
                  </p>
                  <div class="instruction-list">
                      
                  </div>
                  <ul class="instruction-list">
                    <li>
                      <div class="pt-2"><b>1. Create a Shopify Partner Account:</b></div>
                      <div class="pl-4">Sign up Shopify Partner account if you haven't already, <a href="https://www.shopify.com/partners" target="_blank">Click here</a> to sign up account.</div>
                      <div class="pl-4">If you already have Shopify Partner account then <a href="https://www.shopify.com/partners" target="_blank">Click here</a> to sign in account.</div>
                      <div class="pl-4">Once logged in, you'll have access to the Shopify Partner Dashboard where you can create and manage your Shopify apps.</div>
                    </li>

                    

                    <!-- <li>
                      <div class="pt-2"><b>2. Change "App URL" and "Allowed Redirection URL(s)" :</b></div>
                      <div class="pl-4">In the left menu, click on the "Configuration" tab. Under the "URL" section, you'll find inputs for "App URL" and "Allowed redirection URL(s)".</div>
                      <div class="pl-4">Click on the "Click here" link next to "App URL" ( {{ App_URL }} ) <span @click="copyAppUrl()" class="copy_url">Click here</span> to copy the App URL and paste in App URL input.</div> 
                      <div class="pl-4">Similarly, click on the "Click here" link next to "Allowed redirection URL(s)" ( {{ Redirection_URL }} ) <span @click="redirectionURL()" class="copy_url">Click here</span> to copy the Allowed redirection URL(s) and paste it in Allowed redirection URL(s) input.</div> 
                    </li>

                    <li>
                      <div class="pt-2"><b>3. Install Your App in a Shopify Store:</b></div>
                      <div class="pl-4">Once your app is created, you'll want to install it into a Shopify store for testing and development.</div>
                      <div class="pl-4">In the Shopify Partner Dashboard, go to Apps > Your Apps and click on your app.</div>
                      <div class="pl-4">After creating the app, you'll receive API keys (API key and API secret key) that you'll use to authenticate your app with Shopify's API.</div>
                    </li>-->
                    
                  </ul> 
                </div>
                
                <div class="account d-md-none">
                  <router-link to="/Login" class="forgot-password">Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import AuthLeft from "../components/authleft/AuthLeft.vue";
  import axios from "axios";

  export default {
    name: "AddStoreInstruction",
    components: {
      AuthLeft,
    },
  
    data() {
      return {
        App_URL: process.env.VUE_APP_REDIRECT_URL + '/api/getInitialConnection',
        Redirection_URL: process.env.VUE_APP_REDIRECT_URL + '/api/getShopifyAccessToken',
        details: {
          shopifyStoreName: "",
        },
        errorMessage: null,
        isLoadingArray: [],
        para: "Let's add your Shopify Store",
        mem: "Already a member?",
        btn: "Sign In",
      };
    },
  
    validations: {
      details: {
        shopifyStoreName: {
          required,
        },
      },
    },
  
    computed: {
      ...mapGetters(["errors"]),
    },
  
    mounted() {
      this.$store.commit("setErrors", {});
    },
  
    methods: {
      copyAppUrl() {
      // Create a temporary input element
      const input = document.createElement('input');
      input.style.position = 'fixed';
      input.style.opacity = 0;
      input.value = this.App_URL;
      document.body.appendChild(input);
      input.select();

      // Copy the URL to the clipboard
      document.execCommand('copy');

      // Remove the temporary input element
      document.body.removeChild(input);
      this.$toast('App URL copied!');


    },
    redirectionURL() {
      // Create a temporary input element
      const input = document.createElement('input');
      input.style.position = 'fixed';
      input.style.opacity = 0;
      input.value = this.Redirection_URL;
      document.body.appendChild(input);
      input.select();

      // Copy the URL to the clipboard
      document.execCommand('copy');

      // Remove the temporary input element
      document.body.removeChild(input);

      this.$toast('Redirection URL copied!');
    },
      continued() {
        this.errorMessage = null;
        this.$v.$touch();
        if (this.$v.$error) return;
        this.$set(this.isLoadingArray, 0, true);

        // Retrieve registered user ID from local storage
        const registeredUserId = localStorage.getItem("registerdUserId");

        // Make a request to the API endpoint to validate the Shopify store name
        axios.post(process.env.VUE_APP_API_URL + "addStoreName", {
          storeName: this.details.shopifyStoreName,
          userId: registeredUserId // Include registered user ID in the request payload
        })
        .then((response) => {
          console.log("error", response)

            if (response.data.statusCode === 200) {
              this.$set(this.isLoadingArray, 0, false);
              window.location.href = response.data.shopifyAuthUrl;
            } else if (response.data.statusCode=== 409) {
              // Handle invalid store name

              this.errorMessage = response.data.message;
              this.$set(this.isLoadingArray, 0, false);
            }
          })
        .catch((error) => {
          console.error('Error adding store name:', error);
          this.errorMessage = 'An error occurred';
          this.$set(this.isLoadingArray, 0, false);

        });
      },
    },
  };
  </script>
  
<style scoped>
.instruction-list li{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.copy_url{
  color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}
.copy_url:hover{
  color: #007bff;
    text-decoration: underline;
    background-color: transparent;
}
.auth-page-right .auth-page-right-content {
    width: calc(100% - 50px);
    margin: 0px auto;
  }
.auth-page-right .auth-page-right-content .heading {
    height: calc(100vh - 10vh);
    overflow: scroll;
  }
  
</style>